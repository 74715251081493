<template>
  <div id="loginWrapper" class="login-wrapper font-Poppins">
    <div
      id="desktopLoginWrapper"
      class="login md:flex text-black items-center rounded-2xl mt-1 md:mt-0 hidden md:visible"
      :class="{ 'my-2': invalidEmail, 'mt-2': !invalidEmail }"
    >
      <div class="w-1/2 flex">
        <div class="left-wrapper w-11/12 h-screen rounded-r-3xl relative">
          <img
            :src="require('@/assets/images/login/pink-element.png')"
            class="h-16 absolute -right-8 top-20"
          />
          <div class="grid justify-items-center w-full h-full items-end">
            <p
              class="text-white text-5xl tracking-wide text-justify leading-relaxed mt-10"
            >
              Welcome to the<br />future of employee<br />experience
            </p>
            <ul class="text-white list-disc list-inside">
              <li>
                <span class="colorMustard">Configure</span> your onboarding
                journey in easy steps
              </li>
              <li class="mt-4">
                <span class="colorMustard">Customise</span> as per your org
                guidelines
              </li>
              <li class="mt-4">
                <span class="colorMustard">Customer grade</span> experiences get
                delivered at key moments.
              </li>
            </ul>
            <img
              class="w-1/2"
              :src="require('@/assets/images/login/illustration.svg')"
              alt="texperia-hr"
            />
          </div>
        </div>
      </div>
      <div
        class="relative content-center items-center h-screen justify-center rounded-xl w-1/2 flex flex-col gap-4 pb-20 pr-20"
      >
        <img
          class="w-1/4 md:block mt-12"
          :src="require('@/assets/images/logo.svg')"
          alt="texperia-hr"
        />
        <div class="pt-4 pb-10 text-lg font-semibold">
          Hey! Login to create the future of employee experience
        </div>
        <div id="loginForm">
          <form v-if="invalidEmail" @submit.prevent="onSubmitEmail" novalidate>
            <div class="f-outline relative">
              <input
                type="email"
                placeholder=""
                name="email"
                ref="email"
                id="email"
                autocomplete="on"
                v-model="email"
                class="textInputLogin block appearance-none focus:outline-none bg-transparent w-full md:w-48 lg:w-80"
                autofocus
              />
              <label
                for="email"
                class="absolute ml-5 top-0 text-base text-gray-700 bg-white mt-4 -z-1 duration-300 origin-0"
                >Email address</label
              >
              <img
                class="w-7 absolute top-5 right-8"
                :src="require('@/assets/images/login/icon_email.svg')"
                alt="texperia-hr"
              />
            </div>
            <div
              class="text-sm text-red-500 mt-3"
              v-if="v$.email.$invalid && checkValidation"
            >
              {{ v$.email.$silentErrors[0].$message }}
            </div>
            <div class="message pt-2">
              <div v-if="errorMsg" class="error text-red-600 w-2/3">
                <p class="text-sm">{{ errorMsg }}</p>
              </div>
              <div v-if="successMsg" class="success text-green-700 ml-14">
                <p class="text-sm">
                  {{ successMsg }}
                  <a
                    class="text-green-900 underline italic"
                    :href="link"
                    target="_blank"
                    >{{ link }}</a
                  >
                </p>
              </div>
            </div>
            <div class="submit my-3 md:pb-20">
              <ButtonInput
                :buttonText="'get otp'"
                :loading="loading"
                buttonClass="otpButton"
                loadingClass="otpLoading"
                fillColor="#111827"
              />
            </div>
          </form>
          <form v-else @submit.prevent="onSubmit" novalidate>
            <div class="email mt-4 md:mt-0">
              <div class="f-outline relative mt-8">
                <input
                  type="email"
                  placeholder=""
                  name="email"
                  ref="email"
                  id="email"
                  autocomplete="on"
                  v-model="email"
                  class="textInputLogin block appearance-none focus:outline-none bg-transparent w-full md:w-48 lg:w-80"
                  autofocus
                />
                <label
                  for="email"
                  class="absolute ml-5 top-0 text-base text-gray-700 bg-white mt-4 -z-1 duration-300 origin-0"
                  >Email address</label
                >
                <img
                  class="w-7 absolute top-5 right-8"
                  :src="require('@/assets/images/login/icon_email.svg')"
                  alt="texperia-hr"
                />
              </div>
            </div>
            <div class="flex gap-1 place-content-center items-center mt-2">
              <div
                @click.prevent="reloadPage"
                class="block text-red-500 font-medium text-right"
              >
                Login with another email?
              </div>
            </div>
            <div
              class="text-sm text-red-500 mt-3"
              v-if="v$.email.$invalid && checkValidation"
            >
              {{ v$.email.$silentErrors[0].$message }}
            </div>
            <div
              v-if="!invalidEmail"
              class="otp mt-3 transition duration-300 ease-out"
              :class="{ hidden: invalidEmail }"
            >
              <div class="f-outline relative mt-8">
                <input
                  placeholder=" "
                  type="password"
                  name="otp"
                  id="otp"
                  ref="otp"
                  v-model="otp"
                  maxlength="6"
                  autofocus
                  v-on:keyup.enter="onSubmit"
                  class="textInputLogin block appearance-none focus:outline-none bg-transparent w-full md:w-48 lg:w-80 font-sans"
                />
                <label
                  for="otp"
                  class="absolute ml-5 top-0 text-base text-gray-700 bg-white mt-4 -z-1 duration-300 origin-0"
                  >OTP</label
                >
                <img
                  class="w-7 absolute top-4 right-8"
                  :src="require('@/assets/images/login/icon-otp-close.png')"
                  alt="texperia-hr"
                />
              </div>
              <div class="flex gap-1 place-content-center items-center mt-2">
                <span>Didn't receive OTP?</span>
                <button
                  @click.prevent="resendOtp()"
                  class="block text-red-500 font-medium text-right"
                >
                  Resend OTP
                </button>
              </div>
              <div
                class="text-sm text-red-500 mt-3"
                v-if="v$.otp.$invalid && checkValidation"
              >
                {{ v$.otp.$silentErrors[0].$message }}
              </div>
            </div>
            <div
              class="message text-sm font-semibold inline-block w-full my-2 h-4"
            >
              <div v-if="loginError" class="error text-red-600 rounded w-2/3">
                <p class="text-sm">{{ loginError }}</p>
              </div>
              <ul v-if="loginErrorObj" class="text-red-500 text-sm font-medium">
                <li v-for="(err, key) in loginErrorObj" :key="err">
                  {{ key }}: {{ err }}
                </li>
              </ul>
              <div v-if="successMsg" class="success text-green-700">
                <p class="text-sm">
                  {{ successMsg }}
                </p>
              </div>
              <div class="otp" v-if="resend.status">
                <p class="text-yellow-500">
                  {{ resend.message }}
                </p>
              </div>
            </div>
            <div class="submit">
              <ButtonInput
                :buttonText="'login'"
                :loading="loading"
                buttonClass="loginButton"
                loadingClass="loginLoading"
                fillColor="#111827"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      id="mobileLoginWrapper"
      class="block md:hidden relative w-screen h-screen"
    >
      <div
        class="image-content-mobile-wrapper absolute top-10 left-8 w-10/12 h-96 rounded-2xl shadow-2xl text-center"
      >
        <div class="w-full flex place-content-center mt-4">
          <img
            class="w-2/4"
            :src="require('@/assets/images/login/texperia_white_logo.svg')"
            alt="texperia-hr"
          />
        </div>
        <div class="mt-8">
          <lottie-animation
            path="lotties/desktop.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :width="150"
            :height="150"
          />
        </div>
        <div class="mt-4 text-white font-medium">
          This website is accessible only on Desktop browsers.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import ButtonInput from "@/components/Elements/ButtonInput";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "Login",
  components: { ButtonInput, LottieAnimation },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      otp: null,
      invalidEmail: true,
      checkValidation: false,
      loading: false,
      resend: {
        status: null,
        message: null,
      },
      successMsg: null,
      errorMsg: null,
    };
  },
  validations() {
    return {
      email: { required, email },
      otp: { required },
    };
  },
  computed: {
    ...mapGetters(["loginError", "loginErrorObj"]),
  },
  mounted() {},
  methods: {
    onSubmitEmail() {
      this.checkValidation = true;

      if (!this.v$.email.$invalid) {
        this.loading = true;
        this.errorMsg = null;
        ApiService.post(apiResource.auth.validateEmail, {
          email: this.email,
        })
          .then(() => {
            this.errorMsg = null;
            this.invalidEmail = false;
            this.checkValidation = false;
            this.loading = false;
            this.$nextTick(() => {
              this.$refs.otp.focus();
            });
          })
          .catch((error) => {
            this.loading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.loading = false;
      }
    },
    onSubmit() {
      console.log("in submit");
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        this.loading = true;
        this.$store
          .dispatch("validateOtp", {
            email: this.email,
            otp: this.otp,
          })
          .then(() => {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({ name: "Dashboard" });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    resendOtp() {
      this.$store.dispatch("resendOtp", { email: this.email }).then((data) => {
        this.resend.status = true;
        this.resend.message = data.data.msg;
      });
    },
    reloadPage() {
      console.log("in reload");
      // window.location.reload();
    },
  },
};
</script>

<style lang="postcss" scoped>
.login-wrapper {
  background-image: url("~@/assets/images/login/login-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.left-wrapper,
#mobileLoginWrapper {
  background: #231f20;
}
.textInputLogin {
  @apply bg-white rounded-lg text-black px-3 pt-4 pb-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500;
}
.f-outline input:focus-within ~ label,
.f-outline input:not(:placeholder-shown) ~ label {
  @apply font-semibold;
  transform: translateY(-1.6rem) translatex(-0.5rem) scaleX(0.8) scaleY(0.8);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.image-content-text-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  padding: 20px 40px;
}
.image-content-mobile-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 20px 40px;
}
.highlighted-text {
  color: #f6b201;
}
.otpButton {
  @apply border-2 border-gray-900 shadow relative transition duration-300 ease-out text-gray-900 tracking-wider text-xl w-full md:w-48 lg:w-80 py-1.5 bg-white rounded-lg hover:bg-gray-900 hover:text-white uppercase font-medium mr-4;
}
.otpLoading {
  @apply border-2 border-gray-900 cursor-not-allowed shadow relative transition duration-300 ease-out text-gray-900 tracking-wider w-full md:w-48 lg:w-80 py-1.5 bg-white rounded-lg uppercase text-xl font-medium mr-4;
}
.loginButton {
  @apply shadow relative transition duration-300 ease-out text-white tracking-wider text-xl w-full md:w-48 lg:w-80 py-1.5 bg-gray-900 rounded-lg uppercase font-medium mr-4;
}
.loginLoading {
  @apply cursor-not-allowed shadow relative transition duration-300 ease-out text-white tracking-wider w-full md:w-48 lg:w-80 py-1.5 bg-gray-900 rounded-lg uppercase text-xl font-medium mr-4;
}
.colorMustard {
  color: #fdc300;
}
</style>
